export interface StaffAndVesselCodeEntity { 
    id:number, 
    staffCode: string, 
    vesselCode: string|null, 
    billToCompany: string|null, 
    serviceCode: string|null, 
    userInChargeInd: string|null, 
    billingType: string|null, 
    billingTypeVal: string|null,
    activeInd: string|null, 

    [key:string]: string | number | null | undefined 
} 
export const EMPTY_STAFF_VSL_CODE_ENTITY:StaffAndVesselCodeEntity = {
    id: 0,
    staffCode: "",
    vesselCode: "",
    billToCompany: "",
    serviceCode: "",
    activeInd: "Y",
    userInChargeInd: "Y",
    billingType: "SHIP",
    billingTypeVal: "Ship Bounded & Ship Bounded In Period"
} 
