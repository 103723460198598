import { ResponseEntity } from "domain/entity/Common/ResponseEntity";
import { MasterDataType } from "domain/entity/MasterData/MasterDataEntity";
import { EMPTY_STAFF_VSL_CODE_ENTITY, StaffAndVesselCodeEntity } from "domain/entity/StaffAndVesselCode/StaffAndVesselCodeEntity";
import { CompanyRepository } from "domain/repository/Company/CompanyRepo";
import { MasterDataRepository } from "domain/repository/MasterData/MasterDataRepo";
import { StaffAndVesselCodeRepository } from "domain/repository/StaffAndVesselCode/StaffAndVesselCodeRepo";
import { UserRepository } from "domain/repository/User/UserRepo";
import _ from "lodash";
import { createStaffAndVesselCodeMaintenanceValidationSchema } from "presentation/constant/StaffAndVesselCode/StaffAndVesselCodeMaintenanceValidationSchema";
import { Validation } from "presentation/constant/Validation";
import { StaffAndVesselCodeMaintenanceModel } from "presentation/model/StaffAndVesselCode/StaffAndVesselCodeMaintenanceModel";
import { Dispatch, SetStateAction } from "react";
import { GroupCheckboxList, IFieldValue } from "veronica-ui-component/dist/component/core";
import BaseViewModel from "../BaseViewModel";
interface StaffAndVesselCodeMaintenanceVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<StaffAndVesselCodeMaintenanceModel>> | ((value: SetStateAction<StaffAndVesselCodeMaintenanceModel>) => void),
    ],
    repo: StaffAndVesselCodeRepository,
    masterDataRepo: MasterDataRepository,
    companyRepo: CompanyRepository,
    userRepo: UserRepository,
}
export const StaffAndVesselCodeMaintenanceVM = ({ dispatch, repo, masterDataRepo, companyRepo, userRepo }: StaffAndVesselCodeMaintenanceVMProps) => {
    const [staffAndVesselCodeMainDispatch] = dispatch;

    const loadDropdownOption = async () => {

        await masterDataRepo.getMasterDataByKey(MasterDataType.SERVICE).then(
            serviceCodes => {
                const serviceCodeDropdownOptions = serviceCodes?.map((serviceCode) => ({
                    dropdownLabel: serviceCode.code,
                    tagLabel: serviceCode.code,
                    value: serviceCode.code,
                })) ?? []

                staffAndVesselCodeMainDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        serviceCodeDropdownOptions: serviceCodeDropdownOptions
                    }
                }))
            }
        );

        await companyRepo.getAllCompanyForCombobox().then(
            companies => {
                let companyCodeDropdownOption = companies?.map((company) => ({
                    dropdownLabel: company.companyCode,
                    tagLabel: company.companyCode,
                    value: company.companyCode,
                })) ?? []
                companyCodeDropdownOption = _.orderBy(companyCodeDropdownOption, "dropdownLabel");

                staffAndVesselCodeMainDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        billToCompanyDropdownOptions: companyCodeDropdownOption,
                    }
                }))
            }
        )

        await userRepo.getAllUsers().then(users => {
            let newUsers = _.orderBy(users, ["name"]);
            const loginUserDropdownOption = newUsers?.map((user) => ({
                dropdownLabel: user.name,
                tagLabel: user.name,
                value: user.name,
            })) ?? []

            staffAndVesselCodeMainDispatch(prevState => ({
                ...prevState,
                dynamicOptions: {
                    ...prevState.dynamicOptions,
                    loginUserDropdownOption: loginUserDropdownOption,
                }
            }))
        });
    }
    const onSearch = async () => {
        const entities = await repo.getEntities();
        staffAndVesselCodeMainDispatch(prevState => ({
            ...prevState,
            tableData: entities,
            selectedRows: [],
            currentSelectedRow: EMPTY_STAFF_VSL_CODE_ENTITY,
            isBackMaster: false,
            masterState: {
                ...prevState.masterState,
                isSliderOpen: false,
            }

        }))
    }
    const updateSelectedRows = async (selecedRows: StaffAndVesselCodeEntity[]) => {
        staffAndVesselCodeMainDispatch(prevState => {

            return {
                ...prevState,
                selectedRows: selecedRows,
            }
        })
    }
    const onAdd = () => {
        staffAndVesselCodeMainDispatch(prevState => {

            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    isAdd: true,
                    isSliderOpen: true,
                    isEditable: false,
                    isRead: false,
                    editingEntity: EMPTY_STAFF_VSL_CODE_ENTITY,
                }
            }
        })
    }
    const onEdit = (currentEntity: StaffAndVesselCodeEntity) => {
        staffAndVesselCodeMainDispatch(prevState => {
            return {
                ...prevState,
                currentSelectedRow: currentEntity,
                masterState: {
                    ...prevState.masterState,
                    isAdd: false,
                    isEditable: true,
                    isRead: false,
                    editingEntity: {
                        ...currentEntity
                    },
                }
            }
        })
    }
    const onOpenEdit = (currentEntity: StaffAndVesselCodeEntity) => {
        staffAndVesselCodeMainDispatch(prevState => {
            return {
                ...prevState,
                currentSelectedRow: currentEntity,
                masterState: {
                    ...prevState.masterState,
                    isSliderOpen: true,
                    isAdd: false,
                    isEditable: false,
                    isRead: true,
                    editingEntity: {
                        ...currentEntity
                    },
                }
            }
        })
    }
    const onReset = () => {
        staffAndVesselCodeMainDispatch(prevState => {
            const resetEntity = prevState.masterState.isAdd ? EMPTY_STAFF_VSL_CODE_ENTITY : prevState.currentSelectedRow;
            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    editingEntity: {
                        ...resetEntity
                    },
                }
            }
        })
    }
    const onClose = () => {
        staffAndVesselCodeMainDispatch(prevState => {
            return {
                ...prevState,
                currentSelectedRow: EMPTY_STAFF_VSL_CODE_ENTITY,
                selectedRows: [],
                masterState: {
                    ...prevState.masterState,
                    isSliderOpen: false,
                    isAdd: false,
                    isEditable: false,
                    isRead: true,
                    editingEntity: EMPTY_STAFF_VSL_CODE_ENTITY,
                    allFormState: {}
                },
                isBackMaster: true,
            }
        })
    }
    const onSaveClicked = () => {
        staffAndVesselCodeMainDispatch(prevState => {
            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    isSaveClicked: true,
                    allFormState: {},
                }
            }
        })
    }
    const onSave = async (currentEntity: StaffAndVesselCodeEntity, isAdd: boolean) => {
        const valResult = isAdd ? await Validation(createStaffAndVesselCodeMaintenanceValidationSchema).ValidateFormOnly(currentEntity) : null;
        let validatedResult: { [x: string]: string } = {};
        if (valResult) {
            validatedResult = { ...valResult, warningMessage: 'Please input the missing value.' };

            staffAndVesselCodeMainDispatch(prevState => {
                return {
                    ...prevState,
                    masterState: {
                        ...prevState.masterState,
                        allFormState: {
                            ...validatedResult
                        },
                    }
                }
            });
            const res: ResponseEntity = {
                code: "",
                success: false,
                msg: null,
                data: 'Please input the missing value.'
            }

            return res;
        }
        if (isAdd) {
            return await repo.createEntity(currentEntity);
        } else {
            return await repo.updateEntity(currentEntity);
        }
    }
    const onCheckboxChange = (checked: boolean, fieldName: string) => {
        staffAndVesselCodeMainDispatch(prevState => ({
            ...prevState,
            masterState: {
                ...prevState.masterState,
                editingEntity: {
                    ...prevState.masterState.editingEntity,
                    [fieldName]: checked ? "Y" : "N",
                }
            }
        }))
    }
    const onFieldChange = async (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }
        if (fieldKey === 'vesselCode') {
            val = val.toUpperCase().replace(/\s+/g, '');
        }
        staffAndVesselCodeMainDispatch(prevState => {
            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    editingEntity: {
                        ...prevState.masterState.editingEntity,
                        [fieldKey]: val,
                    },
                    allFormState: {
                        ...prevState.masterState.allFormState,
                        [fieldKey]: '',
                    }
                }
            }
        })
    }

    const onGroupCheckboxChange = (e: (GroupCheckboxList | undefined)[], fieldKey: string) => {
        let selectedValue: string[] = [];
        if (e) {
            e.forEach(function (value, index) {
                if (value) {
                    selectedValue.push(value.key);
                }
            });
        }
        staffAndVesselCodeMainDispatch(prevState => ({
            ...prevState,
            masterState: {
                ...prevState.masterState,
                editingEntity: {
                    ...prevState.masterState.editingEntity,
                    [fieldKey]: selectedValue[0],
                },
                allFormState: {
                    ...prevState.masterState.allFormState,
                    [fieldKey]: '',
                }
            }
        }));
    };

    const refreshValueCode = async (billingType: string) => {
        if (billingType === "DOMESTIC") {
            staffAndVesselCodeMainDispatch(prevState => {
                return {
                    ...prevState,
                    masterState: {
                        ...prevState.masterState,
                        editingEntity: {
                            ...prevState.masterState.editingEntity,
                            vesselCode: null,
                            serviceCode: null,
                        },
                    }
                }
            })
        }
        if (billingType === "SHIP") {
            staffAndVesselCodeMainDispatch(prevState => {
                return {
                    ...prevState,
                    masterState: {
                        ...prevState.masterState,
                        editingEntity: {
                            ...prevState.masterState.editingEntity,
                            billToCompany: null,
                        },
                    }
                }
            })
        }
    }

    const onDeleteClick = async (selectedRows: StaffAndVesselCodeEntity[]) => {
        return await repo.deleteEntity(selectedRows.map(entity => entity.id));
    }

    return {
        onDeleteClick: onDeleteClick,
        refreshValueCode: refreshValueCode,
        loadDropdownOption: loadDropdownOption,
        updateSelectedRows: updateSelectedRows,
        onAdd: onAdd,
        onEdit: onEdit,
        onOpenEdit: onOpenEdit,
        onReset: onReset,
        onClose: onClose,
        onSearch: onSearch,
        onSaveClicked: onSaveClicked,
        onSave: onSave,
        onCheckboxChange: onCheckboxChange,
        onFieldChange: onFieldChange,
        onGroupCheckboxChange: onGroupCheckboxChange,
    }
} 
