import { StaffAndVesselCodeMaintenanceConstant } from "./StaffAndVesselCodeMaintenanceConstant";
const SCREEN_CONSTANT = StaffAndVesselCodeMaintenanceConstant;
export const INITIAL_STAFF_VSL_CODE_COL_DEF: any[] = [
    {
        headerName: SCREEN_CONSTANT.LOGIN_USER,
        field: "staffCode",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 160,
        pinned: "left",
        checkboxSelection: true,
        headerCheckboxSelection: true,

    },
    {
        headerName: SCREEN_CONSTANT.SOA,
        field: "vesselCode",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 100,
    },
    {
        headerName: SCREEN_CONSTANT.BILL_TO,
        field: "billToCompany",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 180,
    },
    {
        headerName: SCREEN_CONSTANT.SERV_CODE,
        field: "serviceCode",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 150,
    },
    {
        headerName: SCREEN_CONSTANT.BILLING_TYPE,
        field: "billingTypeVal",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 150,
    },
    {
        headerName: SCREEN_CONSTANT.USER_IN_CHARGE,
        field: "userInChargeInd",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 160,
    },
    {
        headerName: SCREEN_CONSTANT.ACTIVE_IND,
        field: "activeInd",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 130,
    },
] 
